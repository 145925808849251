import React from "react"
import JSONData from "branding/lp-metadata.json"
import AutoData from "./data/autocta.json"
import CtaDisclosure from "@tightrope/ctadisclosure"
import FooterLinks from "@tightrope/footerlinks/footer.js"
import Styles from "./css/autocta.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
var $ = require('jquery');

class Autocta extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: AutoData
    }
  }
  headline(data){
    let params = new URLSearchParams(window.location.search);
    let hc = params.get("hc");
    let kw = params.get("kw");
    let bn = params.get("bn");
    let qsData = {};
    if(hc){
      qsData.headline = hc;
    }
    if(kw){
      qsData.subhead = kw;
    }
    if(bn){
      qsData.mainCTA = bn;
    }
    if(qsData){
      const queryData = Object.assign(data, qsData);
      this.setState({data: queryData});
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    setTimeout(function()  {
			$(".autocta-module--animation").fadeOut();
  		  if ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) && (navigator.userAgent.toLowerCase().indexOf("edg") == -1)) {
          $("#autocta-module--qualified").fadeIn(3000);
          setTimeout(function() {
  	        $(".autocta-module--animationModal").fadeOut(2000);
  			 	  setTimeout(function() {
  		        $(".autocta-module--card").fadeIn(2000);
            }, 2000);
  		    }, 3000);
      } else {
  		 $("#autocta-module--notQualified").fadeIn(3500);
  		}

  		}, 3500);
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    this.headline(this.state.data);
    if (typeof window != "undefined") {
        window.mapParams();
    }

    });
  }

  render() {
    return (
      <div id = {Styles.autowrap} style={{ display: this.state.display }}>
        <div className={Styles.height}>
      		  <div className={Styles.fullscreen}>
          <div className={Styles.animationModal}>
            <img src={this.state.data.prodLogo1} className={Styles.productLogo} alt="Manuals Search Logo" />

          <div className={Styles.animation}>
             <div className={Styles.progress}>
              <img src={this.state.data.prodIcon} className={Styles.productIcon} />
                  <span className={Styles.title+' '+Styles.timer}
                    data-from="0"
                    data-to="100"
                    data-speed="1800"></span>
                  <div className={Styles.overlay}></div>
                  <div className={Styles.left}></div>
                  <div className={Styles.right}></div>
             </div>

            <div className={Styles.copy}>
              <h1>{this.state.data.animation1}</h1>
              <p>{this.state.data.availablefor}</p>
            </div>
      	</div>


            <div id={Styles.qualified}>
              <img src={this.state.data.successimg} alt="Compatible" />
              <div className={Styles.copy}>
                <h2>{this.state.data.compat}</h2>
                <h1>{this.state.data.compatCopy}</h1>
              </div>
            </div>

      		    <div id={Styles.notQualified}>
              <img src={this.state.data.notCompat} alt="Not Compatible" />
              <div className={Styles.copy}>
      		<h1>{this.state.data.notcompat}</h1>
                <h2>{this.state.data.notcompatCopy}</h2>

              </div>
            </div>

          </div>
      <div className={Styles.card} style={{display: 'none'}}>
      <div className={Styles.wrap}>
      <img className={Styles.cardlogo} src={this.state.data.prodLogo2}/>
      <h1>{this.state.data.cardHeadline}</h1>
      <h2>{this.state.data.cardSubhead}</h2>
      		<p className={Styles.Pwrap}>{this.state.data.cardPara}</p>

            <button className={Styles.st2} data-cy="cta" onClick = {() => window.triggerInstall()}
              dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></button>



      	<CtaDisclosure></CtaDisclosure>
      </div>
      </div>
        </div>

              <FooterLinks></FooterLinks>
              </div>
</div>
    )
  }
}

export default Autocta
