import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Autocta from "src/components/modules/autocta"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"

const autoData = import("./data/data.json");

const Img = () => <img className="cws" src="/img/cws-badge.png" data-cy="cta" onClick = {() => window.triggerInstall()} />;

export default function autoctaSpa() {

  return(
    <HomepageLayout>
      <Helmet>

        <style type="text/css">{`
            #ctadisclosure-module--ctadisclaimer {
              color: #fafafa;
              padding: 15px 50px 10px!important;
            }

            #autocta-module--autowrap .autocta-module--cardlogo {
              width: 150px;
            }

      `}
         </style>

      <title>Freshy Search Manuals- freshysearch.com</title></Helmet>
      <Autocta data={autoData}><Img /><CtaDisclosureSearch data={autoData}></CtaDisclosureSearch></Autocta>
    </HomepageLayout>
  )
}
